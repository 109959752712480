import { useCallback, useState } from 'react';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useTheme } from 'styled-components';
import { Container } from 'components/Container';
import { CategoryMenu } from 'components/CategoryMenu';
import { DropdownButton } from 'components/Button';
import { FlexContainer } from 'components/FlexContainer';
import { BlogHeaderLink } from './BlogHeaderLink.styled';
import { DropdownButtonWrapper } from './DropdownButtonWrapper.styled';

import type { FC } from 'react';
import type { BlogCategory } from 'types/blog/category';

interface BlogHeroContainerProps {
  currentPage: string;
  categories: BlogCategory[];
  isMainHeader?: boolean;
}

export const BlogHeroContainer: FC<BlogHeroContainerProps> = ({
  currentPage,
  categories,
  isMainHeader = false,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = useCallback(() => {
    setShowDropdown(current => !current);
  }, []);

  const { breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('sm'));

  return (
    <>
      <Container
        padding={10}
        bottomPadding={1}
        manageLayout={false}
        color={isMobile ? 'white' : 'cream'}
      >
        <FlexContainer justifyContent="space-between">
          <BlogHeaderLink to="/blog" noUnderline>
            {isMainHeader ? <h1>Blog</h1> : <h2>Blog</h2>}
          </BlogHeaderLink>
          <DropdownButtonWrapper>
            <DropdownButton onClick={toggleDropdown} showDropdown={showDropdown} />
          </DropdownButtonWrapper>
        </FlexContainer>
      </Container>
      <CategoryMenu
        currentPage={currentPage}
        categories={categories}
        hideCategoryMenu={!showDropdown}
      />
    </>
  );
};
