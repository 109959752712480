import styled from 'styled-components';
import { getDimension } from 'styled/helpers/getDimension';

export const ContainerInner = styled.div<{ alignItems?: 'unset' | 'center' }>`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  gap: ${getDimension('size15')};
  grid-template-areas: '. .';
  align-items: ${({ alignItems }) => alignItems || 'center'};

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: flex;
    flex-direction: column;
    gap: ${getDimension('size8')};
    align-items: unset;
  }
`;
