import styled from 'styled-components';

import { Container } from 'components/Container';

import { getDimension } from 'styled/helpers/getDimension';

export const BlogPostContainer = styled(Container)`
  margin-top: ${getDimension('size3')};

  img {
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 0;
  }
`;
