import { StaticImage } from 'gatsby-plugin-image';

import Calculator from 'images/shared-page-assets/Calculator.inline.svg';
import Gauge from 'images/shared-page-assets/Gauge.inline.svg';
import ChartScatter from 'images/shared-page-assets/ChartScatter.inline.svg';

import type { SimpleCard } from 'components/Cards';
import type { BlogPost } from 'types/blog/blogPost';

export const etfCards: SimpleCard[] = [
  {
    cardIdentifier: 'etf-card-1',
    title: 'Track your ETFs, stocks and more',
    text: 'Get automatically updated price and performance data on 240,000+ global stocks, ETFs, crypto and much more.',
    image: <Gauge />,
  },
  {
    cardIdentifier: 'etf-card-2',
    title: 'See your ETF distribution income',
    text: 'Calculate your annualised return including capital gains, dividends, distributions and currency fluctuations.',
    image: <Calculator />,
  },
  {
    cardIdentifier: 'etf-card-3',
    title: 'Benchmark against ETFs',
    text: 'Compare against a world of investments – benchmark against stocks, ETFs or funds.',
    image: <ChartScatter />,
  },
];

export const blogPosts: BlogPost[] = [
  {
    id: 'benchmark-your-portfolio-against-any-stock-etf-or-fund',
    title: 'Benchmark your portfolio against any stock, ETF or fund',
    urlSlug: '/blog/benchmark-your-portfolio-against-any-stock-etf-or-fund/',
    content: '',
    image: {
      gatsbyImageData: null,
      title: 'Benchmark your portfolio',
      staticImage: (
        <StaticImage
          src="../images/etf-tracker/benchmark-your-portfolio.jpg"
          alt="Benchmark your portfolio banner"
        />
      ),
    },
    categories: [{ name: 'Product features', urlSlug: '/blog/sharesight-features-tips' }],
    author: 'Stephanie Stefanovic',
    authorTitle: 'Content Manager',
    authorCompany: 'Sharesight',
    description:
      'Investors using Sharesight can benchmark their portfolios against any stock, ETF, mutual/managed fund or unit trust that Sharesight currently supports.',
    date: new Date('2022-08-18'),
  },
  {
    id: 'etf-vs-stocks',
    title: 'ETFs vs. stocks',
    urlSlug: '/blog/etf-vs-stocks/',
    content: '',
    image: {
      gatsbyImageData: null,
      title: 'ETFs vs. stocks',
      staticImage: (
        <StaticImage src="../images/etf-tracker/etf-vs-stocks.png" alt="Etf vs Stocks banner" />
      ),
    },
    categories: [{ name: 'Investing tips', urlSlug: '/blog/investing-tips' }],
    author: 'Stephanie Stefanovic',
    authorTitle: 'Content Manager',
    authorCompany: 'Sharesight',
    description:
      'Stocks and ETFs can both be a valuable addition to an investor’s portfolio, however it’s important to understand the pros and cons of these two asset types.',
    date: new Date('2023-10-14'),
  },
  {
    id: 'how-to-track-exchange-traded-funds-etfs',
    title: 'How to track exchange-traded funds (ETFs)',
    urlSlug: '/blog/how-to-track-exchange-traded-funds-etfs/',
    content: '',
    image: {
      gatsbyImageData: null,
      title: 'How to track exchange-traded funds (ETFs)',
      staticImage: (
        <StaticImage src="../images/etf-tracker/track-etfs.jpeg" alt="Track ETFs banner" />
      ),
    },
    categories: [{ name: 'Investing tips', urlSlug: '/blog/investing-tips' }],
    author: 'David Olsen',
    authorTitle: 'Senior Marketing Manager',
    authorCompany: 'Sharesight',
    description:
      'Like all investments, it’s critical to track the performance of and distributions earned by exchange-traded funds (ETFs) in your portfolio.',
    date: new Date('2021-10-20'),
  },
];
