import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';

import { useGatsbyImage } from 'hooks/useGatsbyImage';
import { useContentfulPage } from 'hooks/useContentfulPage';

import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { PartnerLink, SignupLink } from 'components/Links';
import { Container, HeroContainer, Section, SectionImage } from 'components/Container';
import { Cards } from 'components/Cards';
import { PricingBlock } from 'components/PricingBlock';
import { ContentBlock } from 'components/ContentBlock';
import { BlogCards } from 'components/Blog';

import { blogPosts, etfCards } from 'page-data/etf-tracker';

import { BrokerAnimation } from 'components/BrokerAnimation';
import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

export const EtfTracker: FC<LocalizedPageProps> = () => {
  const {
    translations: { brokersBlockText },
    sections,
  } = useContentfulPage();

  const { currentLocale } = useLocalizationContext();
  const isAu = currentLocale.path === 'au';

  const allYourDividendsImg = useGatsbyImage({
    name: 'shared-page-assets/localised/taxable-income-report',
    alt: 'Taxable Income Report',
  });

  return (
    <Layout>
      <Seo />

      <HeroContainer>
        <Section>
          <h1>The X-Ray tool for ETF investors</h1>
          <p>
            See inside your ETFs and avoid overlap in your portfolio with Sharesight's exposure
            report - The ultimate X-ray tool for ETF investors.
          </p>

          <br />
          <br />

          <SignupLink asButton />
        </Section>

        <SectionImage
          image={useGatsbyImage({
            name: 'etf-tracker/hero',
            alt: 'graphic illustration of a pie chart depicting an ETF x-ray tool',
          })}
          boxShadow={false}
        />
      </HeroContainer>
      <Cards cards={etfCards} />
      <Container>
        <Section>
          <h2>See inside your ETFs</h2>
          <p>
            Know what you’re actually invested in and eliminate overlap in your portfolio.
            Sharesight’s <strong>exposure report</strong> shows the combination of shares, bonds and
            securities inside your ETFs.
          </p>
        </Section>
        <SectionImage
          image={useGatsbyImage({
            name: 'etf-tracker/exposure-report',
            alt: "user interface of Sharesight's exposure report",
          })}
          boxShadow={false}
        />
      </Container>

      {!!sections && sections.length > 0 && isAu && (
        <ContentBlock
          header={sections[0]?.header}
          textContent={sections[0]?.textContent}
          image={allYourDividendsImg}
          padding={15}
        />
      )}
      <Container>
        <Section>
          <h2>We work with all your favourite brokers and apps</h2>
          <p>{brokersBlockText}</p>
          <br />
          <PartnerLink
            to="partners"
            asButton
            appearance="secondary"
            title="Brokers & Apps | Sharesight"
            showArrowIcon
          >
            View our partners
          </PartnerLink>
        </Section>
        <Section span={3}>
          <BrokerAnimation background={isAu ? 'white' : 'cream'} />
        </Section>
      </Container>
      <PricingBlock />
      <Container>
        <Section>
          <BlogCards entries={blogPosts} />
        </Section>
      </Container>
    </Layout>
  );
};

export default EtfTracker;
