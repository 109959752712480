import styled from 'styled-components';

import { Container } from 'components/Container';
import { getDimension } from 'styled/helpers/getDimension';

export const MenuContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin: 0 ${getDimension('size4')};
    flex-basis: 200px;
    max-width: 100%;
  }
`;
