import styled from 'styled-components';
import { getDimension } from 'styled/helpers/getDimension';

export const MenuItem = styled.li`
  float: left;
  padding-right: ${getDimension('size8')};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    float: none;
  }
`;
