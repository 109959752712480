import styled, { css } from 'styled-components';

interface CategoryMenuWrapperProps {
  hideCategoryMenu: boolean;
}

export const CategoryMenuWrapper = styled.div<CategoryMenuWrapperProps>`
  display: block;

  ${props =>
    props.hideCategoryMenu &&
    css`
      ${({ theme }) => theme.breakpoints.down('sm')} {
        display: none;
      }
    `}
`;
